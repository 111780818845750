import Link from 'next/link';
import React from 'react'

const FooterCategories = ({title, data, isClient}) => {
    return isClient &&  (
        <div className='lg:col-span-2'>
          <h3 className='font-bold uppercase'>{title}</h3>
          <ul className='mt-4 flex flex-col gap-4'>
            {data?.map((option, index) => (
              <li key={index} className='opacity-70 max-sm:text-sm'>
                <Link href={`/${option.url_key}`}>{option.name}</Link>
              </li>
            ))}
          </ul>
        </div>
    );
}

export default FooterCategories
