import { registerValidation } from '@/utils/valitation/auth';
import { register } from '@/services/auth';
import { getCookie } from 'cookies-next';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';

import toast from 'react-hot-toast';
import EyeIcon from '@/components/icons/EyeIcon';
import Eyelock from '@/components/icons/Eyelock';
import Link from 'next/link';

const UserSignUp = ({ t, setAuth }) => {
  const handshake = getCookie('handshake');
  const intialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    terms: '',
    mobile: '',
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleRegister = async (values, { resetForm }) => {
    setLoading(true);
    console.log('values', values);
    const { data, error } = await register(handshake, values);
    if (data?.data?.success === 'false' || error) {
      // handling the error coming from backend and show to client
      toast.error(
        data?.data?.errormsg || error?.error?.message || t('Resister Failed')
      );
    } else {
      toast.success(t('Resister success'));
      resetForm();
      setAuth('Login');
    }
    return setLoading(false);
  };

  // const handleRegister = async (values, { setSubmitting, resetForm }) => {
  //   setSubmitting(true);
  //   // const userInputs = {
  //   //   ...values,
  //   //   profile_picture: 'string',
  //   //   dateOfBirth: '2001-01-01',
  //   //   gender: '1',
  //   //   mobile: '966'.concat(values.mobile.slice(1)),
  //   // };
  //   const { data, error } = await register(handshake, values);
  //   if (error) {
  //     // handling the error coming from backend and show to client
  //     toast.error('Resister Faild');
  //     setSubmitting(false);
  //     return;
  //   } else {
  //     console.log('register data', data);
  //     toast.success('Resister success');
  //     resetForm();
  //     setSubmitting(false);
  //     return;
  //   }
  // };
  return (
    <section className='mt-3 sm:mt-5'>
      <p className='text-center text-sm *:ms-1 sm:text-lg'>
        <span className='font-bold text-primary'>
          {t('If you have an account')}
        </span>
        <span className='text-[#787272]'>
          , {t('Log in using your email.')}
        </span>
      </p>
      <Formik
        onSubmit={handleRegister}
        initialValues={intialValues}
        validationSchema={registerValidation}
      >
        {({ errors, touched }) => (
          <Form className='mt-2.5 grid grid-cols-2 gap-3'>
            <div className='*:mb-1 max-sm:col-span-full'>
              <Field
                name='firstname'
                className='w-full rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:p-2'
                placeholder={t('First Name')}
                maxLength={20}
              />
              {errors.firstname && touched.firstname ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.firstname)}
                </p>
              ) : null}
            </div>
            <div className='*:mb-1 max-sm:col-span-full'>
              <Field
                name='lastname'
                className='w-full rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:p-2'
                placeholder={t('Last Name')}
                maxLength={20}
              />
              {errors.lastname && touched.lastname ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.lastname)}
                </p>
              ) : null}
            </div>
            <div className='col-span-full *:mb-1'>
              <Field
                className='w-full rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:p-2'
                name='mobile'
                placeholder={t('5xxxxxxxx')}
                type='text'
                id='mobile'
                maxLength={10}
              />
              {errors.mobile && touched.mobile ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.mobile)}
                </p>
              ) : null}
            </div>
            <div className='col-span-full *:mb-1'>
              <Field
                name='email'
                type='email'
                className='w-full rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:p-2'
                placeholder={t('Email Address')}
              />
              {errors.email && touched.email ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.email)}
                </p>
              ) : null}
            </div>
            <div className='col-span-full'>
              <div className='relative *:mb-1'>
                <Field
                  name='password'
                  type={!showPassword ? 'password' : 'text'}
                  className='w-full rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:p-2'
                  placeholder={t('Password')}
                />
                {!showPassword ? (
                  <Eyelock
                    onClick={() => setShowPassword(!showPassword)}
                    className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 cursor-pointer text-zinc-500'
                  />
                ) : (
                  <EyeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 cursor-pointer text-zinc-500'
                  />
                )}
              </div>
              {errors.password && touched.password ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.password)}
                </p>
              ) : null}
            </div>
            <div className='col-span-full'>
              <div className='relative *:mb-1'>
                <Field
                  name='confirmPassword'
                  type={!showConfirm ? 'password' : 'text'}
                  className='w-full rounded-md border p-1.5 outline-none max-sm:text-sm max-sm:placeholder:text-sm sm:p-2'
                  placeholder={t('Confirm Password')}
                />
                {!showConfirm ? (
                  <Eyelock
                    onClick={() => setShowConfirm(!showConfirm)}
                    className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 cursor-pointer text-zinc-500'
                  />
                ) : (
                  <EyeIcon
                    onClick={() => setShowConfirm(!showConfirm)}
                    className='absolute end-2 top-1/2 z-20 size-4 -translate-y-1/2 cursor-pointer text-zinc-500'
                  />
                )}
              </div>
              {errors.confirmPassword && touched.confirmPassword ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.confirmPassword)}
                </p>
              ) : null}
            </div>
            <div className='col-span-full '>
              <div className='flex items-center gap-1.5'>
                <Field
                  name='terms'
                  type='checkbox'
                  id='terms-conditions'
                  className='size-4 cursor-pointer appearance-none rounded-md border checked:bg-primary sm:size-[23px]'
                />
                <label
                  //htmlFor='terms-conditions'
                  className='text-[#525050]'
                >
                  {t('I accept')}{' '}
                  <Link
                    href={'/static/terms'}
                    target='_blank'
                    className='cursor-pointer underline sm:font-bold'
                  >
                    {t('terms & Conditions')}
                  </Link>
                </label>
              </div>
              {errors.terms && touched.terms ? (
                <p className='text-sm text-red-600 max-sm:text-xs'>
                  {t(errors.terms)}
                </p>
              ) : null}
            </div>
            <button
              type='submit'
              disabled={loading}
              className='col-span-full flex w-full items-center justify-center rounded-md bg-primary p-1.5 text-sm font-bold text-white disabled:cursor-not-allowed disabled:opacity-75 sm:text-lg'
            >
              {loading ? (
                <span className='size-4 animate-spin rounded-full border-r border-t border-white' />
              ) : (
                t('Register')
              )}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default UserSignUp;
